<template>
  <v-container
    id="newsslist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Шаблоны абонементов
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Шаблоны абонементов</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="createTemplate"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['id']"
            :sort-desc="[true]"
            @click:row="singleTemplate"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }
  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import moment from 'moment'
  import subscriptionsApi from '../services/SubscriptionsApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    filters: {
      formatBirthday: function (date) {
        return moment(date).format('DD.MM.YYYY')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    data () {
      return {
        newsCategoryFilter: CRMStorage.getItem('STL_newsCategoryFilter', []),
        newsCategories: [],
        search: CRMStorage.getItem('STL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('STL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('STL_onlyArchived', false),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Название',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Стоимость ',
            value: 'price',
          },
          {
            sortable: true,
            text: 'Занятий',
            value: 'lessons_count',
          },
        ],
        items: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        news: {
          name: null,
          text: null,
          newscategory_id: null,
          url: null,
        },

        selectedNews: null,
      }
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('STL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('STL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('STL_page', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('STL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('STL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Шаблоны абонементов | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      subscriptionsApi
        .fetchSubscriptionTemplates()
        .then(response => {
          this.items = this.items.concat(response)
          this.loading = false
        })
        .catch(error => {
          this.handleError(error)
        })
    },
    methods: {
      singleTemplate (item) {
        CRMStorage.setItem('STL_scrollY', window.scrollY)
        this.$router.push('/template/' + item.id)
      },
      createTemplate (news) {
        CRMStorage.setItem('STL_scrollY', window.scrollY)
        this.$router.push('/template/create')
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }
</style>
